let hostname = window.location.hostname;
let api_base_url = false;
let token = null;

console.log("hostname", hostname);

function setBaseUrl(config) {
	if (config?.mode == "local") {
		api_base_url = "http://" + config.server_ip + "/api";
	} else {
		if (hostname === "localhost" || hostname.includes(".localhost"))
			api_base_url = "http://api.sdm.localhost";
		else if (hostname.includes(".dev.")) api_base_url = "https://api.dev.yellgo.cloud";
		else if (hostname.includes(".devaws.")) api_base_url = "https://api.dev.yellgo.cloud";
		else if (hostname.includes(".demo.")) api_base_url = "https://api.demo.yellgo.cloud";
		else api_base_url = "https://api.yellgo.cloud";
	}
	console.log("api_base_url", api_base_url);
}

async function login(request) {
	const response = await fetch(api_base_url + "/users/login/", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Apiver: 2,
			Hostname: hostname,
		},
		body: JSON.stringify(request),
	});

	const json = await response.json();

	token = json.token;

	return json;
}

async function refreshToken(t) {
	const response = await fetch(api_base_url + "/users/refresh_token/", {
		method: "GET",
		headers: {
			Apiver: 2,
			Authorization: "Bearer " + t,
			Hostname: hostname,
		},
	});

	const json = await response.json();

	token = json.token;

	return json;
}

async function autoLogin(id) {
	const response = await fetch(api_base_url + "/users/bo_autologin/" + id + "/", {
		method: "GET",
		headers: {
			Apiver: 2,
			Hostname: hostname,
		},
	});

	const json = await response.json();

	token = json.token;

	return json;
}

async function get(endpoint) {
	const response = await fetch(api_base_url + endpoint, {
		method: "GET",
		headers: {
			Apiver: 2,
			Hostname: hostname,
		},
	});

	const json = await response.json();

	return json;
}

async function getProtected(endpoint) {
	const response = await fetch(api_base_url + endpoint, {
		method: "GET",
		headers: {
			Apiver: 2,
			Hostname: hostname,
			Authorization: "Bearer " + token,
		},
	});

	const json = await response.json();

	return json;
}

async function post(endpoint, request) {
	const response = await fetch(api_base_url + endpoint, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Apiver: 2,
			Hostname: hostname,
		},
		body: JSON.stringify(request),
	});

	const json = await response.json();

	return json;
}

async function postProtected(endpoint, request) {
	const response = await fetch(api_base_url + endpoint, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Apiver: 2,
			Hostname: hostname,
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify(request),
	});

	const json = await response.json();

	return json;
}

export default {
	setBaseUrl,
	login,
	refreshToken,
	autoLogin,
	post,
	get,
	getProtected,
	postProtected,
};
