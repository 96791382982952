import { useContext } from "react";
import Api from "./api/api";
import { AppContext } from "../App";
import jwt_decode from "jwt-decode";

function Login() {
	const { user, setUser } = useContext(AppContext);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		let request = Object.fromEntries(data.entries());
		const login = await Api.login(request);
		if (login.success == 0) alert(login.error);
		else {
			const decoded = jwt_decode(login.token);
			localStorage.setItem("pos-user", login.token);
			const response = await Api.getProtected("/users/get/" + decoded.user_id + "/");
			if (response.success == 0) alert(response.error);
			else setUser(response.data);
		}
	};

	return (
		<div className="login">
			<form className="content" onSubmit={handleSubmit}>
				<h3>YellGO Tracking Manager</h3>
				<input type="email" name="email" autoComplete="username" />
				<input type="password" name="password" autoComplete="current-password" />
				<button className="button button-1">Login</button>
			</form>
		</div>
	);
}

export default Login;
